/* eslint-disable*/
import VueStoreService from './VueStoreService';
const AreasTemplateService = {
    async getAreas(filter,store){
        var resp = await VueStoreService.get("template_area"+filter, store)
        return resp;
    },
    async saveArea(data,store){
        var resp = await VueStoreService.post("template_area",data,store)
        return resp;
    },
    async getAreaById(area_id,store){
        var resp = await VueStoreService.get( "template_area/"+area_id,store)
        return resp;
    },
    async updateArea(area_id,data,store){
        var resp = await VueStoreService.put("template_area/"+area_id,data,store)
        return resp;
    },

    async deleteArea(area_id,store){
        var resp = await VueStoreService.delete("template_area/"+area_id,store)
        return resp;
    },
}

export default AreasTemplateService;